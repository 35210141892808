import { render, staticRenderFns } from "./AgeRestriction.vue?vue&type=template&id=741cc330&scoped=true&"
import script from "./AgeRestriction.vue?vue&type=script&lang=js&"
export * from "./AgeRestriction.vue?vue&type=script&lang=js&"
import style0 from "./AgeRestriction.vue?vue&type=style&index=0&id=741cc330&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "741cc330",
  null
  
)

export default component.exports